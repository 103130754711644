<template>
  <div>
    <el-row class="bgW">
      <el-col :span="18">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
           <el-form-item label="日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="渠道类型" >
            <el-cascader
            v-model="sourceType"
            :options="options.typeList"
            :props="{ checkStrictly: true }"
            @change="changeSourceType"
            />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="6" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button type="primary" class="mb10" size="mini" @click="taskFrame = true">添加目标</el-button>
        <el-button type="primary" class="mb10" size="mini" @click="advertisingFrame = true">添加广告费</el-button>
      <el-button type="primary" class="mb10" size="mini" :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
      </el-row>


    <div class="lister">
      <!--列表-->
      <el-row >
        <el-col :span="24" class="xxixi">
          <el-table
            :data="tableData"
            size="small"
            max-height="650"
            border
            v-loading="loading"
            :span-method="objectSpanMethod"
            :cell-style="cellStyle"
            :header-cell-style="rowClass"
          >
            <ElTableColumn label="市场名称" prop="market_name" fixed/>
            <ElTableColumn label="门店"  fixed>
              <template slot-scope="{row}">
                <span v-if="row.shop_name !== '合计'">{{row.shop_name}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="进店目标" prop="shop_task" />
            <ElTableColumn label="预约个数" prop="all_yuyue_num" />
            <ElTableColumn label="进店总数">
              <template slot-scope="{row}">
                <span style="cursor: pointer;" @click="goCustomerDetail(row,row.all_into_num,1)"  v-if="row.market_name !== '合计'">{{row.all_into_num}}</span>
                <span v-else>{{row.all_into_num}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="疤痕进店">
              <template slot-scope="{row}">
                <span style="cursor: pointer;" @click="goCustomerDetail(row,row.all_bh_num,2)"  v-if="row.market_name !== '合计'">{{row.all_bh_num}}</span>
                <span v-else>{{row.all_bh_num}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="青春痘进店" width="90">
              <template slot-scope="{row}">
                <span style="cursor: pointer;" @click="goCustomerDetail(row,row.all_dd_num,3)"  v-if="row.market_name !== '合计'">{{row.all_dd_num}}</span>
                <span v-else>{{row.all_dd_num}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="皮肤进店">
              <template slot-scope="{row}">
                <span style="cursor: pointer;" @click="goCustomerDetail(row,row.all_xm_num,4)"  v-if="row.market_name !== '合计'">{{row.all_xm_num}}</span>
                <span v-else>{{row.all_xm_num}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="成交总数" >
              <template slot-scope="{row}">
                <span style="cursor: pointer;" @click="goCustomerDetail(row,row.all_new_order_num,5)"  v-if="row.market_name !== '合计'">{{row.all_new_order_num}}</span>
                <span v-else>{{row.all_new_order_num}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="疤痕成交数"  width="90">
              <template slot-scope="{row}">
                <span style="cursor: pointer;" @click="goCustomerDetail(row,row.all_bh_deal,6)"  v-if="row.market_name !== '合计'">{{row.all_bh_deal}}</span>
                <span v-else>{{row.all_bh_deal}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="青春痘成交数" width="100">
              <template slot-scope="{row}">
                <span style="cursor: pointer;" @click="goCustomerDetail(row,row.all_dd_deal,7)" v-if="row.market_name !== '合计'">{{row.all_dd_deal}}</span>
                <span v-else>{{row.all_dd_deal}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="皮肤成交数"  width="90">
              <template slot-scope="{row}">
                <span style="cursor: pointer;" @click="goCustomerDetail(row,row.all_xm_deal,8)"  v-if="row.market_name !== '合计'">{{row.all_xm_deal}}</span>
                <span v-else>{{row.all_xm_deal}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="进店率" prop="store_entry_rate" />
            <ElTableColumn label="成交率" prop="turnover_rate" />
            <ElTableColumn label="疤痕成交率" prop="bahen_rate" width="90"/>
            <ElTableColumn label="新单成交额" prop="all_new_order_money" width="90"/>
            <ElTableColumn label="总成交额" prop="all_order_money" />
            <ElTableColumn label="广告费" prop="advertising_expenses" />
            <ElTableColumn label="新单ROI" prop="monthROI" />
            <ElTableColumn label="整体ROI" prop="allROI" />
          </el-table>
        </el-col>
      </el-row>
    </div>
    <!-- 新增弹框 -->
    <el-dialog title="新增门店月度进店目标" :visible.sync="taskFrame"
    :close-on-click-modal="false" width="550px" @close="closeTask">
      <el-form
        ref="taskDetail"
        label-position="right"
        label-width="120px"
        :rules="rules"
        :model="taskDetail"
        class="frame"
        size="mini"
      >
        <el-form-item label="门店名称:" prop="shop_id">
          <el-select
              v-model="taskDetail.shop_id"
              clearable
              remote
              filterable
              v-loading="loading"
              class="w300"
              :remote-method="remoteShop"
              placeholder="请输入门店名称"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
        </el-form-item>
        <el-form-item label="渠道类型:" prop="type">
          <el-select
              v-model="taskDetail.type"
              clearable
              remote
              filterable
              class="w300"
              placeholder="请选择渠道类型"
            >
              <el-option
                v-for="item in options.taskChannelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
        </el-form-item>
        <el-form-item label="目标数量" prop="task_num">
          <el-input-number class="w300" v-model="taskDetail.task_num" :min="-10000" :precision="0" ></el-input-number>
        </el-form-item>
        <el-form-item label="年/月" prop="moneth">
          <el-date-picker
            v-model="time"
            type="month"
            class="w300"
            value-format="yyyy-MM"
            @change="changeTime(time,'task')"
            placeholder="选择月份">
          </el-date-picker>
        </el-form-item>
        <div class="footerBtn mt50">
          <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('taskDetail','task')">完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
     <!-- 新增弹框 -->
     <el-dialog title="新增月度门店广告费用" :visible.sync="advertisingFrame"
    :close-on-click-modal="false" width="550px" @close="closeAdvert">
      <el-form
        ref="advertDetail"
        label-position="right"
        label-width="120px"
        :rules="advertRules"
        :model="advertDetail"
        class="frame"
        size="mini"
      >
        <el-form-item label="门店名称:" prop="shop_market_id">
          <el-select
              v-model="advertDetail.shop_market_id"
              clearable
              remote
              filterable
              class="w300"
              :remote-method="remoteShop"
              placeholder="请输入门店名称"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
        </el-form-item>
        <el-form-item label="年/月" prop="moneth">
          <el-date-picker
            v-model="time"
            type="month"
            class="w300"
            value-format="yyyy-MM"
            @change="changeTime(time,'advert')"
            placeholder="选择月份">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="广告费用" prop="advertising_expenses">
          <el-input v-model="advertDetail.advertising_expenses" class="w300" placeholder="请输入广告费用">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <div class="footerBtn mt50">
          <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('advertDetail','advert')">完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getLastDay } from '@/utils';
import { channelTypeList,taskChannelList } from '@/utils/constants'
import { getShopList } from "@/api/shop";
import { shopTask,monethShop,moneth_mk_task } from "@/api/statistics";

class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class TaskDetail {
  type = "";//渠道类型
  shop_id = ""; // 店铺id
  task_num = 1; // 任务数量正整数
  year = ''; // 年份
  moneth = ''; // 月份
}
class Rules {
 shop_id = [
    { required: true, message: "请选择门店", trigger: "change" }
  ];
  type = [
    { required: true, message: "请选择渠道类型", trigger: "change" }
  ];
 task_num = [
    { required: true, message: "请输入目标数量", trigger: "change" }
  ];
 year = [
    { required: true, message: "请输选择年份", trigger: "change" }
  ];
 moneth = [
    { required: true, message: "请选择月份", trigger: "change" }
  ];

}
class AdvertDetail {
  shop_market_id = ""; // 店铺id
  year = ''; // 年份
  moneth = ''; // 月份
  advertising_expenses = ''; // 当月广告费用，两位小数点的正数
}
class AdvertRules {
 shop_market_id = [
    { required: true, message: "请选择门店", trigger: "change" }
  ];
 year = [
    { required: true, message: "请输选择年份", trigger: "change" }
  ];
 moneth = [
    { required: true, message: "请输选择月份", trigger: "change" }
  ];
 advertising_expenses = [
    {
      pattern: /^([-])?(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
      message: "最多保留两位小数",
      trigger: "change"
    },{ required: true, message: "请输输入广告费用", trigger: "blur" }
  ];
}

class Options {
  shop_id = [];
  typeList = channelTypeList;
  taskChannelList = taskChannelList;
}
class Search {
  start_time = "";
  end_time = "";
  source_type = 'mendian';
}
export default {
  name: "OrderList",
  components: {
    // pagination
  },
  data() {
    return {
      sourceType:'mendian',
      search: new Search(), // 检索条件
      taskDetail: new TaskDetail(), //
      advertDetail: new AdvertDetail(), //
      rules: new Rules(), // 校验
      advertRules: new AdvertRules(), // 校验
      options: new Options(), //选项
      page: new Page(), // 分页
      list: [], // 数据展示
      taskFrame: false,
      advertisingFrame: false,
      loading: false,
      time: '',
      exportS: false,
     
    };
  },
  computed: {
      tableData() {
        const res = this.list.reduce((res, item) => {
          item.maxnum = 0;
          if (item.list && item.list.length > 0) {
            const goods = item.list.map(goods => Object.assign({}, item, goods));
            goods[0].maxnum = item.list.length;
            res = res.concat(goods);
          } else {
            res.push(item);
          }
          return res;
        }, []);
        return res;
      }
    },

  mounted() {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01'
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList('search');
    this.getShopList();
  },
  methods: {
    changeSourceType(e){
      console.log(e,"e")
      this.search.source_type = e.at(-1)
    },
    getShopList(){
      getShopList({ page: 1, limit: 100000 }).then(res => {
        this.options.shop_id = res.list;
      });
    },
    getList(option) {
      this.loading = true
      if (option === "search") this.page = new Page();
      monethShop({ ...this.search,export:0 }).then(res => {
        this.list = res.list;
        this.loading = false
      });
    },
        // 门店查找
   remoteShop(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },
    goCustomerDetail(row,count,type){
      if(count == 0){
        this.$message({
          message: '暂无数据',
          type: 'warning'
        });
      }else{
        let query = row
        this.$set(query, 'type', type);
        console.log(query,"query11")
        let route = this.$router.resolve({ path: "/statistics/monthstatementDetail", query: query });
        window.open(route.href, '_blank');
      }
    },
    // 选择时间
    changeTime(e,type){
      var index = e.lastIndexOf("-");
      if(type == 'task'){
        this.taskDetail.year = e.substring(0, index);
        this.taskDetail.moneth = e.substring(index+1,e.length);
      }else{
        this.advertDetail.year = e.substring(0, index);
        this.advertDetail.moneth = e.substring(index+1,e.length);
      }
    },
    // 关闭弹窗
    closeTask(){
      this.taskDetail = new TaskDetail()
      this.time = ''
      this.$refs['taskDetail'].resetFields();
      this.getShopList();

    },
    // 关闭弹窗
    closeAdvert(){
      this.advertDetail = new AdvertDetail()
      this.time = ''
      this.$refs['advertDetail'].resetFields();
      this.getShopList();

    },
    submitForm(formName,type) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let msg = type == 'task' ? '添加月度进店目标任务' : '添加月度门店广告费用'
          this.hint(msg,type);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    hint(text,type) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm(type);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    confirm(type){
      if(type == 'task'){
        shopTask({ ...this.taskDetail }).then(res => {
        console.log(this.taskDetail)
          this.$message({
          type: "success",
          message: res.msg
          });
          this.taskFrame = false;
          this.getList();
        });
      }else{
        moneth_mk_task({ ...this.advertDetail }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.advertisingFrame = false;
          this.getList();
        });
      }
   
    },
    // 导出
    exportExcel() {
      this.exportS = true
      monethShop({...this.search,export:1}).then(res => {
        window.open(res.url);
        this.exportS = false
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          rowspan: row.maxnum,
          colspan: 1
        };
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
    },
    cellStyle({row, column, rowIndex, columnIndex}){
        if(columnIndex <= 3){
            return 'background:#FFF3CA;text-align:center'
            // return 'background:#CCFFFF;text-align:center'
        }
        if(columnIndex > 3 && columnIndex <= 11){
            return 'background:#7CDED7;text-align:center'
            // return 'background:#FDE9D9;text-align:center'
        }
        if(columnIndex > 11){
            return 'background:#FADADE;text-align:center'
            // return 'background:#FFFF99;text-align:center'
        }
        return '';
      },
    rowClass({row, column, rowIndex, columnIndex}){
      return 'background:#E9EDEE;text-align:center'

    },
  }
};
</script>

<style lang="scss" scoped>
.footerBtn,.btnBox {
  text-align: right;

}

.lister,.xxixi{
	background-color: green ;
}
::v-deep .el-cascader-panel{
  height: 300px!important;
}
::v-deep .el-cascader-menu__wrap{
  height: 300px !important;
}
</style>
